/**
*   button.scss
*   @description: Estilos para los botones
*/

/*---------------------- ESTILOS EN USO ------------------------*/

@mixin btn {
	cursor: pointer;
	font-family: $Mukta;
	font-size: $fontSmall;
	font-weight: $weightSemi;
	background-color: transparent;
	padding: $paddingMedium $paddingSemi;
	transition: all 200ms ease-in-out;
	&:active { transform: scale(0.95); }
}

@mixin hover-shine {
	text-shadow: 0px 0px 6px rgba(255, 255, 255, .5);
	transition: all 0.4s ease 0s;
}

$cancelHover: #9e9e9e85;

.btn {
    cursor: pointer;
    font-family: $Mukta;
    //font-size: $fontSmall;
    font-size: 0.95rem;
    font-weight: 300;
    //font-weight: $weightSemi;
    background-color: transparent;
    //padding: $paddingMedium $paddingSemi;
    padding: 2px 18px;
    transition: all 200ms ease-in-out;
    &:active { transform: scale(0.95); }
}

.btn-billing-modal {
    font-size: .8rem;
    width: 20% !important;
}

.btn-cancel {
	@include btn;
    border-bottom-right-radius: 4px;
	background: $secondary;
	color: white;
}

.btn-modal-delete-cancel {
	@include btn;
	width: 50%;
    border-bottom-right-radius: 4px;
	background: $secondary;
	color: white;

	&:hover {
		@include hover-shine;
	}
}

.btn-modal-delete-success {
	@include btn;
	width: 50%;
    border-bottom-left-radius: 4px;
	background: $purple;
	color: white;

	&:hover {
		@include hover-shine;
        background-color: rgba($purple, 0.8);
	}
}  

.btn-disable {
	border-radius: 5px;
	background-color: rgba($gray, 0.8) ;
}

.btn-edit {
    border-radius: 5px;
    background-color: $primary;
    padding: 8px 24px;
    font-size: .8rem;
    &:hover { background-color: rgba($primary, 0.8); }
}

.btn-red-table {
	FONT-WEIGHT: 900;
    border-radius: 2px;
    background-color: #EA545F;
    padding: 4px 20px;
    font-size: .8rem;
}

.width-small {
	width: 8%;
}

.width-100 {
    width: 100%;
}

/*----------------------------------------------------------------*/

button {
    cursor: pointer;
    font-family: $Mukta;
    background-color: transparent;
    &:disabled {
        cursor: not-allowed;
    }
}
.btn-position{
    display: flex;
    height: 100%;
    align-items: flex-end;
}

.btn-mini {
    padding: 2px 12px;
    font-size: .8rem;
}

.btn-medium {
    width: 50%;
}

.btn-full{
    width: 100% !important;
}

.btn-primary {
    border-radius: 5px;
    background-color: $purple;
    &:hover { background-color: rgba($purple, 0.8); }
}

.btn-secondary {
	border-radius: 2px;
    background-color: $secondary;
    &:hover { background-color: rgba($purple, 0.8)}
}

.btn-success {
    border-radius: 5px;
    background-color: $success;
    &:hover { background-color: rgba($success, 0.8); }
}

.btn-small {
    //padding: $paddingNormal $paddingMedium;
    padding: 2px 24px;

    i {
        font-size: 0.83rem;
    }
}

.btn-extrasmall {
    padding: $paddingSmall $paddingSmall;
}

.btn-filter {
    cursor: pointer;
    background-color: $black;
    color: $white;
    border-radius: 5px;
    font-size: $fontSmall;
    font-weight: $weightBold;
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);*/
    transition: background-color 300ms ease-in-out;

    &:hover {
        background-color: $primary;
    }
}

.btn-transparent {
    transition: color 300ms ease-in-out;
    background-color: transparent;
    color: black;
    &:hover {
        background-color: transparent;
        color: $primary;
    }
}

.btn-buscar .btn-primary {
    margin-left: 6px;
    //background-color: $secondary;
}

.btn-horarios .btn-secondary {
    border-radius: 2px;
    color: white !important;
    background-color: $secondary !important;
    &:hover { background-color: rgba($secondary, 0.8); }
}

.btn-link:hover{
    color: $primary;
}

.btn-action-success, .btn-action-cancel {
    border-radius: 5px;
}

.btn-action-s {
    width: 120px;
    border-radius: 5px;
    padding: 3px;
    //margin-right: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    color: white;
    transition: all 300ms ease-in-out;
    background-color: $purple;;

    &:hover {
        //background-color: #8c949b;
        background-color: rgba($purple, 0.8);
    }
}

.btn-action-c {
    border-radius: 5px;
    width: 120px;
    padding: 3px;
    margin-right: 8px;
    font-size: 0.95rem;
    font-weight: 500;
    color: $purple;
    transition: all 300ms ease-in-out;
    background-color: #9e9e9e59;

    &:hover {
        background-color: $cancelHover;
    }
}

#btn-modal-edit {
    i {
        font-size: 0.68rem;
    }
}

.btn-change {
    padding: 2px 20px;
}

.accordion .icon-rotate {
    transition: transform 0.3s ease;
  }
  
  .icon-down {
    transform: rotate(-90deg);
  }
/**
*   header.scss
*   @description: Estilos del Header
*/
@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");

header {
    padding: $paddingNormal 0;
	background-color: $blue;
	z-index: 2;

    .options {
        width: 30%;
        margin-right: 16px;
    }

    img {
        // height: 60px;
        width: 260px;
    }
    .nav-size{
        // width: auto;
		min-width: 200px;
		max-width: 400px;
    }
    // .nav-exit:hover{
    //     color: $primary;
    // }
    .nav-item {
        padding: 0 $paddingNormal;
        border-bottom: 2px solid transparent;
        transition: border-bottom 300ms ease-in-out;
        margin-left: 8px;
        padding: 4px 4px;
        &:hover { border-bottom: 2px solid $white; }
        i { padding-right: 4px; }
    }

    .nav-active {
        border-bottom: 2px solid $purple;
        margin-left: 8px;
        padding: 4px 4px;
    }

}

.header-modals {
    padding: 8px 8px 8px 16px;
    border-radius: 4px 4px 0 0;
    background-color: $black;
    width: 100%;
    position: relative;

    .btn-modal-close {
        position: absolute;
        right: 16px;
        top: 10px;
        color: white;
        font-size: 1rem;

        &:hover {
            color: $primary;
            transition: color 300ms ease-in-out;
        }
    }
}

.header-close {
    border-radius: 4px 4px 0 0;
    background-color: $black;
    width: 100%;

    .btn-modal-close {
        color: white;
        font-size: 1.2rem;

        &:hover {
            color: $primary;
            transition: color 300ms ease-in-out;
        }
    }
}

.header-slide {
	z-index: 5;
    /*.logo {
        width: 26%;
    }*/
    height: 72px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    .logo {
        width: 80%;
    }
    
    .options {
        .icon-main {
            color: $white;
            transition: color .300s ease-in-out;
            font-size: $fontRegular;
            cursor: pointer;
            
            &:hover {
                color:  $red;
            }
        }
    }

    .header-selects {
        .select-options {
            position: relative;
            margin-left: 16px;
            width: 250px;

            &:last-child {
                width: 200px;
            }
    
            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                appearance: none;
                padding: 0 .5em;
                color: $black;
                cursor: pointer;
              }
              /* Remove IE arrow */
              select::-ms-expand {
                display: none;
              }

             
              /* Custom Select */
              .select {
                position: relative;
                display: flex;
                width: 100%;
                height: 2.5em;
                line-height: 3;
                background: $white;
                overflow: hidden;
                border-radius: 4px;
              }
         
              /* Arrow */
            .select-arrow{
                position: absolute;
                z-index: 2;
                top: 4px;
                right: 12px;
                border-radius: 0 4px 4px 0;
                height: 100%;
                font-size: $fontRegular;
                cursor: pointer;
                pointer-events: none;
                transition: .25s all ease;
                color: black;
            }
            
            &:hover {
                .select-arrow {
                    color: $red;
                }
            }
        }
    }
  
}
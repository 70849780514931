/**
*   card-quick.scss
*   @description: Estilos de tarjetas quick
*/

.card-quick {
    margin: 0 16px;
    border-radius: 4px;    
    padding: $paddingNormal $paddingNormal $paddingNormal $paddingMedium;
    background-color: $white;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);

    &:first-child { margin: 0 16px 0 0; }
    &:last-child { margin: 0 0 0 16px; }

    .icon {
        padding-right: $paddingMedium;
    }

    .info {
        h5 { 
            font-size: 1.4rem; 
        }
    }

}
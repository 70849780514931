.tooltip {
	position: relative;
	display: inline-block;
	cursor: pointer;
	font-family: "Mukta", sans-serif;
  
	&::after {
	  content: "";
	  position: absolute;
	  background: rgba(0, 0, 0, 0.8);
	  color: #fff;
	  border-radius: 3px;
	  padding: 3px 6px;
	  font-size: 14px;
	  white-space: nowrap;
	  opacity: 0;
	  visibility: hidden;
	  transition: opacity 0.2s, visibility 0.2s;
	  z-index: 999;
	}

	&.size::after {
		width: 200px;
		text-align: center;
		white-space: normal;
	}
  
	&.top::after {
	  content: attr(data-tooltip);
	  top: -35px;
	  left: 50%;
	  transform: translateX(-50%);
	}
  
	&.bottom::after {
	  content: attr(data-tooltip);
	  bottom: -35px;
	  left: 50%;
	  transform: translateX(-50%);
	}
  
	&.left::after {
	  content: attr(data-tooltip);
	  top: 50%;
	  right: 100%;
	  transform: translateY(-50%);
	}
  
	&.right::after {
	  content: attr(data-tooltip);
	  top: 50%;
	  left: 100%;
	  margin: 0px 4px;
	  transform: translateY(-50%);
	}
  
	&:hover::after {
	  opacity: 1;
	  visibility: visible;
	}
  }
  
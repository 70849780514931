.cambiar-password {
	width: 100%;
	padding: 2px;
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	//box-shadow: 1px 0 20px rgba(0,0,0,.05);

	.content { 
		width: 100%;
		display: flex;
		justify-content: center;
	}

	img { height: 150px; }

	.input-container {
        padding: 0 $paddingLarge;
        input {
            width: 100%;
            border-radius: 4px;
            border: 2px solid rgba($gray, 0.5);
            padding: $paddingNormal $paddingNormal;
        }
	}

	.btn-container {
        padding: 0 $paddingLarge;
        button {
            width: 100%;
            padding: $paddingNormal;
        }
	}

	.contraseña-politica {
		display: flex;
		height: 100%;
		width: 45%;
		position: relative;
		justify-content: center;

		.contraseña-politica-content {
			position: absolute;
			text-align: center;
			bottom: 10px;
			padding: $paddingNormal;
			border-radius: 20px;
			border: 2px solid rgba($gray, 0.5);

			ul {
				display: flex;
				flex-direction: column;
				text-align: left;
				margin-left: 20px;
				list-style-type:circle;
			}
		}
	}

	.pass-eye {
        position: relative;

        i {
            position: absolute;
            top: 14px;
            right: 48px;
        }
    }
}

.ReactPasswordStrength {
	input {
		max-height: 42px;
		font-size: 14px;
	}
}

.ReactPasswordStrength-strength-desc {
	top: 27px;
    right: -2px;
}

@media screen and (min-width: 1500px) {
	.cambiar-password {
		.pass-eye {
			position: relative;

			i {
				position: absolute;
				top: 17px !important;
				right: 50px !important;
				font-size: 1.1rem !important;
			}
		}
	}
}

@media screen and (min-width: 1700px) {
	.cambiar-password {
		.pass-eye {
			position: relative;

			i {
				position: absolute;
				top: 13px !important;
				right: 50px !important;
				font-size: 1.1rem !important;
			}
		}
	}
}
.billing {
	.dinamic {
		margin-bottom: 16px;
	}

	.titles-descriptors {
		.label-title-config {
			margin-right: 24px;

			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}

	.billing-rows {
		.label-title-config {
			width: 50%;
		}

		.label-title-descriptors {
			width: 100%;
		}

		.options-descriptors {
			margin-right: 24px;

			&:nth-child(4n) {
				margin-right: 0;
			}
		}

		.label-title {
			width: 24%;
		}

		.label-title-duo {
			width: 61%;
		}

		.label-midle {
			padding: 0px 32px 0px 32px;
			width: 37%;
		}
	}

	.small-row {
		width: 40%;
	}

	.btn-primary {
		margin-right: 8px;
		/*padding: 7px 32px;
		height: 50px;
		min-width: 180px;*/
	}

	.btn-secondary {
		background: #6c757d;
		color: white;
		padding: 7px 32px;
		height: 50px;

		&:hover {
			background-color: #79848d;
			color: white;
		}
	}
}

.column-imp {
	width: 140%;
}

.table-product {
	padding: 8px !important;

	th {
		font-size: 1rem;
	}

	td {
		font-size: 0.95rem;
	}
	.th-options {
		width: 65px !important;
		font-size: 1rem;
	}
}

.table-container {
	max-height: 270px;
	overflow-y: scroll;
}

.table-history-container {
	max-height: 400px;
	overflow-y: scroll;
}

.table-history {
	width: 100%;
	position: relative;
	max-height: 400px;
	border-bottom: 1px solid #eee;

	td {
		border-right: 1px solid #cfcfcf;
	}

	td,
	th {
		//border: 1px solid #e3e3e3;

		text-align: left;
		padding: 0px 16px;
		font-size: 0.93rem;
		font-weight: 500;

		input,
		select {
			background-color: transparent;
		}
	}

	th {
		position: sticky;
		background-color: #e6e6e6;
		//background-color: #e6e6e67a;
		top: 0;
		z-index: 1;
		border-bottom: 1px solid #cfcfcf;
		border-top: 1px solid #cfcfcf;
	}

	.text-right {
		text-align: end;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.row-space {
		td,
		th {
			padding: 4px 24px;
		}
	}

	.not-ok {
		/*background-color: #ea545f;
		input {
			color: #e3e3e3!important;
		}*/
		background-color: #ea545f2e;
	}

	tr .no-border {
		border-right: none;
	}

	.border-right {
		border-right: 1px solid #cfcfcf;
	}

	.border-left {
		border-left: 1px solid #cfcfcf;
	}

	tfoot {
		td {
			border-bottom: 1px solid #cfcfcf;
		}
	}
}

.table-rules {
	position: relative;
	width: 100%;

	td, th {
		border: 1px solid #e3e3e3;
		text-align: left;
		padding: 0px 16px;
		font-size: 0.93rem;
	}

	thead th {
		background-color: #e6e6e6 !important;
	}

	// th {
	// 	position: sticky;
	// 	top: 0;
	// 	// z-index: 1;
	// }

	.text-right {
		text-align: end;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.td-transparent {
		background: white;
		border: 0px;
	}

	.row-space {
		td,
		th {
			padding: 4px 24px;
		}
	}

	.space-left {
		padding-left: 32px;
	}

	.row-total {
		td {
			border: 0;
			padding: 4px 24px;
		}
	}

	.td-input {
		width: 15%;
	}

	// thead:nth-child(1) {
	// 	background-color: #e6e6e6;
	// 	padding: 4px 4px;
	// }
}

.informes-selector {
	position: relative;
	width: 100%;
	flex: 1;
	margin-block: 10px;
	overflow: auto;

	.text-right {
		text-align: end;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.td-transparent {
		background: white;
		border: 0px;
	}

	.detallados-table {
	
		td,
		th {
			padding: 0px 0.85rem;
			font-size: 0.93rem;
			font-weight: 500;
	
			input,
			select {
				background-color: transparent;
			}
		}

		th {
			text-align: left;
			background-color: #e6e6e6;
			border-bottom: 1px solid #cfcfcf;
			border-top: 1px solid #dbdbdb;
		}

		td {
			text-align: right;
			background-color: white;
			border-right: 1px solid #cfcfcf;
		}	

		table {
			border-collapse: separate;
			border-spacing: 0px 0px;

			width: 100%;
			padding-bottom: 8px;
		}

		thead {
			th {
				position: sticky;
				top: 0px;
				z-index: 2;
			}
		}

		tbody {
			tr {
				&:nth-last-child(n + 2):nth-child(even) {
	
					td {
						background-color: #eee;
	
						&:first-child {
							background-color: #eee;
						}
					}
				}

				&:last-child {

					td {
						background-color: #FFF;
						font-weight: bold;
					}
				}
			}
		}

		&.anclar-filas-totales {

			tbody tr {
				&:nth-last-child(1) td {
					position: sticky;
					border-top: 1px solid #dbdbdb;
					bottom: 0px;
					z-index: 1;
				}
			}
		}
	}

	.concentrados-table {

		--row-cols: 4;

		--offset-0: 0px;
		--offset-1: 0px; //78.3px;
		--offset-2: 0px; //calc(78.3px + 78.27px);
		--offset-3: 0px; //calc(78.3px + 78.27px + 164.09px);

		--anchored-border: 1px solid black;
		--bottom-borders: 1px solid hsl(0, 0%, 97%);
		--inline-borders-light: 1px solid #cfcfcf;
		--inline-borders-bold: 1px solid #dbdbdb;

		td,
		th {
			padding: 0px 0.85rem;
			font-size: 0.93rem;
			font-weight: 500;

			input,
			select {
				background-color: transparent;
			}
		}

		td {
			background-color: white;
		}

		th {
			text-align: center;
			background-color: #e6e6e6;
			border-bottom: 1px solid #cfcfcf;
			border-top: 1px solid #dbdbdb;
		}

		table {
			border-collapse: separate;
			border-spacing: 0px 0px;

			width: 100%;
			padding-bottom: 8px;
		}

		thead tr th {
			position: sticky;
			top: 0px;
			z-index: 2;

			&:last-child {
				border-left: var(--inline-borders-bold);
			}
		}

		tbody tr {
			&:nth-child(1) {
				td {
					position: sticky;
					top: 27px;//30px;
					border-right: unset;
					border-bottom: 1px solid #cfcfcf;
					z-index: 1;
					text-align: center;
				}
			}
			
			&:nth-child(n + 2):nth-last-child(n + 2) td:nth-last-child(n + 2) {
				border-right: 1px solid #e3e3e3;
			}

			&:nth-last-child(n + 3):nth-child(even) td {
				background-color: #eee;
			}

			&:nth-last-child(-n + 2) td {
				background-color: #FFF;
				font-weight: bold;
			}

			&:nth-last-child(2) td {
				border-top: var(--inline-borders-bold);
			}

			&:last-child td {
				border-bottom: 1px solid #cfcfcf;
				border-top: var(--inline-borders-light);

				&:nth-child(n + 2) {
					text-align: right;
				}

				&:nth-last-child(n + 2) {
					border-right: var(--inline-borders-light);
				}
			}
		}

		&.desglosar-totales tbody tr {
			&:nth-last-child(n + 2) td {
				&:nth-last-child(n + 2):nth-child(4n + 1) {
					border-right: var(--inline-borders-light);
					&:nth-last-child(5) { border-right: unset; }
				}
				&:nth-last-child(4) {
					border-left: var(--inline-borders-light);
				}
			}

			&:last-child td {
				&:nth-last-child(2) { border-right: unset; }
				&:nth-last-child(1) { border-left: var(--inline-borders-light); }
			}

			&:nth-child(n + 2):nth-last-child(n + 3) td:nth-last-child(-n + 4) {
				font-weight: bold;
			}
		}

		&.no-desglosar-totales tbody tr {
			&:nth-last-child(n + 2) td {
				&:nth-last-child(n + 2):nth-child(2n + 1) {
					border-right: var(--inline-borders-light);
					&:nth-last-child(3) { border-right: unset; }
				}
				&:nth-last-child(2) {
					border-left: var(--inline-borders-light);
				}
			}

			&:last-child td {
				&:nth-last-child(2) { border-right: unset; }
				&:nth-last-child(1) { border-left: var(--inline-borders-light); }
			}

			&:nth-child(n + 2):nth-last-child(n + 3) td:nth-last-child(-n + 2) {
				font-weight: bold;
			}
		}

		&.anclar-columnas-totales {

			thead tr th {
				&:last-child {
					position: sticky;
					width: auto;
					right: 0px;
					z-index: 3;
				}
			}

			tbody tr:last-child td {

				&:last-child {
					position: sticky;
					width: auto;
					right: 0px;
				}
			}

			&.desglosar-totales tbody {
				tr {
					&:nth-last-child(n + 2) td {
						&:nth-last-child(-n + 4) {
							position: sticky;
						}
						&:nth-last-child(1) {
							right: var(--offset-0);
						}
						&:nth-last-child(2) {
							right: var(--offset-1);
						}
						&:nth-last-child(3) {
							right: var(--offset-2);
						}
						&:nth-last-child(4) {
							right: var(--offset-3);
						}
						&:nth-last-child(5) {
							border-right: unset;
						}
					}
				}
			}

			&.no-desglosar-totales tbody tr {
				&:nth-last-child(n + 2) td {
					&:nth-last-child(-n + 4) {
						position: sticky;
					}
					&:nth-last-child(1) {
						right: var(--offset-0);
					}
					&:nth-last-child(2) {
						right: var(--offset-1);
					}
					&:nth-last-child(3) {
						border-right: unset;
					}
				}
			}
		}

		&.anclar-filas-totales {

			tbody tr {
				&:nth-last-child(2) td {
					position: sticky;
					z-index: 1;
					bottom: 27px;//30px;
				}
		
				&:nth-last-child(1) td {
					position: sticky;
					bottom: 0px;
					z-index: 1;
				}
			}
		}
	}
}

.table-apply-payment {
	position: relative;
	width: 100%;
	max-height: 325px;//70%;
	overflow: auto;

	@mixin cellUpdate($name, $color1, $color2) {

		@keyframes #{$name} {
			0% {
				background-color: $color1;
			}

			20% {
				background-color: $color2;
			}

			100% {
				background-color: $color1;
			}
		}

		animation-name: $name;
		animation-duration: 1s;
		animation-iteration-count: 1;
	}

	td, th {
		text-align: left;
		padding: 0px 0.85rem;
		font-size: 0.93rem;
		font-weight: 500;

		input,
		select {
			background-color: transparent;
		}
	}

	th {
		background-color: #e6e6e6;
		border-bottom: 1px solid #cfcfcf;
		border-top: 1px solid #cfcfcf;
	}

	td {
		background-color: white;
		border-right: 1px solid #cfcfcf;
	}

	table {
		border-collapse: separate;
		border-spacing: 0px 0px;

		&.enable-shadow {

			thead th:first-child {
				box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.28);
				clip-path: inset(0px -8px 0px 0px);
			}

			tbody td:first-child {
				border-right: unset;
				box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.28);
				clip-path: inset(0px -8px 0px 0px);
			}
		}
	}

	.text-right {
		text-align: end;
	}

	.text-left {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.td-transparent {
		background: white;
		border: 0px;
	}

	thead {

		th {
			position: sticky;
			top: 0px;
			z-index: 2;

			&:first-child {
				position: sticky;
				left: 0;
				z-index: 3;
			}
		}
	}

	tbody {

		tr {

			&:nth-child(odd) {
				&.cell-update {

					td:nth-child(n+5){
						@include cellUpdate(updatewhite, white, hsl(282, 49%, 70%));
					}
				}
			}

			&:nth-child(even) {

				td {
					background-color: #eee;

					&:first-child {
						background-color: #eee;
					}
				}

				&.cell-update {

					td:nth-child(n+5){
						@include cellUpdate(updategrey, #eee, hsl(282, 49%, 55%));
					}
				}
			}

			td {

				&:first-child {
					position: sticky;
					left: 0;
					z-index: 1;
					background-color: white;
					// border-right: unset;

					padding-block: 2px;
				}
			}
		}
	}

	.pago-aplicado {

		thead tr {
			th:last-child {
				position: sticky relative;
				right: 0;
				z-index: 3;

				padding: 0 0;
				padding-left: 4px;

				border-left: 1px solid #cfcfcf;
			}
		}

		tbody {

			tr {

				&:first-child {

					.documents-header {
						border-top: unset;
					}
				}

				&:nth-child(even) {

					td {
						&:last-child {
							background-color: #eee;
						}
					}
				}

				&:last-child {
					position: sticky;
					background-color: white;
					bottom: 0;
					z-index: 1;

					td {
						border-top: 1px solid #cfcfcf;
						background-color: white;

						@include cellUpdate(updatewhite, white, hsl(282, 49%, 70%));

						&:last-child {
							background-color: white;
						}
					}
				}

				td {

					&:nth-last-child(2) {
						border-right: unset;
					}

					&:last-child {
						position: sticky;
						right: 0;
						background-color: white;
						z-index: 1;
						border-left: unset;

						padding: 0 0;
						padding-left: 16px;
						border-left: 1px solid #cfcfcf;

						> {
							position: relative;
						}
					}

					&.documents-header {
						position: relative;
						padding-inline: 0px;
						padding-left: 0px;
						padding-right: 0px;
						border-left: unset;
						border-right: unset;
						height: 25px;

						border-top: 1px solid #cfcfcf;
						border-bottom: 1px solid #cfcfcf;

						> div {
							position: sticky;
							left: 0;
							display: flex;
							width: 100%;
							height: 25px;
							justify-content: center;
							align-items: center;
							color: black;
							background-color: white;
							font-family: Arial, Helvetica, sans-serif;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}

// table{
//     // margin: 0;
//     // padding:0;
//     // // position: relative;
//     // .th-sticky{
//     //     position: sticky;
//     //     top:0;
//     //     background: white;
//     // }
// }

.table-responsive-table {
	overflow-y: scroll;
	max-height: 500px;
}

.table-responsive-payment {
	max-height: 250px;
	overflow-y: auto;
}

.table-responsive-sat {
	overflow-y: auto;
	height: 50vh;
	min-height: 300px;
	max-height: 500px;

	tr:nth-child(even) {
		background-color: #eee;
	}
}

.table-responsive-receipment {
	overflow-y: auto;
	max-height: 400px;

	.col-unidad {
		width: 20%;
	}

	.col-metodo {
		width: 15%;
	}
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 0;
	font-weight: bold;
	font-size: 1.1rem !important;
	padding-bottom: 6px !important;
}

.react-datepicker__month .react-datepicker__month-text {
	display: inline-block;
	width: 4rem !important;
	margin: 3px;
	padding: 4px 5px !important;
	font-size: 1rem !important;
	text-transform: capitalize !important;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range {
	border-radius: 0.3rem;
	background-color: #ea545f !important;
	color: #fff;
	transition: all 250ms ease-in-out;

	&:hover {
		background-color: #dedede !important;
		color: black !important;
	}
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
	border-radius: 0.3rem;
	background-color: #ea545f !important;
	color: #fff;
	transition: all 250ms ease-in-out;

	&:hover {
		background-color: #dedede !important;
		color: black !important;
	}
}

.container-check-billing {
	width: 100% !important;

	.checkmark {
		border-radius: 0;
	}
}
.new-search-container {
	display: flex;
	align-items: center;

	.sub-container {
		display: flex;
		align-items: center;
		text-align: center;
		input {
			//background: #e9e9e9;
		}
		i {
			color: black;
		}
	}
	.btn-search {
		text-align: center;
		height: 38px;
		font-size: 1rem;
		// background: lightgray;
		color: black;
	}
}
.viviendas-container-check {
	/*display: flex;
	flex-wrap: wrap;*/
	border-radius: 4px;
    border: 1px solid #8e8e8e70;
	display: grid;
	grid-template-columns: repeat(4,1fr);
	grid-gap: 0.8rem;
	padding: 11px 13px;
	// justify-content: space-between;
	max-height: 289px;
	overflow-y: auto;
	align-items: flex-start;
	align-items: center;
	.viviendas-render {
		//width: 20%;
		height: auto;
		display: flex;
		//justify-content: space-between;
		//padding-right: 1rem;
		//padding-left: 1rem;

		.check {
			display: flex;
			align-items: center;
		}
	}
}
.center-all {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	// align-content: center;
}

// AUXILIARES

.column-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	p {
		// align-self: flex-start;
		margin-top: 3px;
	}
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 1366px) {
	.table-responsive-sat {
		max-height: 500px;
	}

	.table-apply-payment {

		max-height: 230px;
		
	}
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 992px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
}

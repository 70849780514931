/**
*   card-margin.scss
*   @description: Margen en Modales
*/
.colum-modal{
    margin: 2px;
    position: relative;
    border: red 1px solid;
    border-radius: 1em;
    padding: .5em;
}
.title-modal{
    position: absolute;
    margin: 1em;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -19px;
    background-color: white;
}

.col-sm{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.img-personal-perfil {
	display: flex;
	justify-content: center;
	align-content: flex-start;
	height: 200;
	width: 100%;

	img {
		max-width: 250px;
		cursor: pointer;
	}
}

.img-width {
	width: 250px;
	padding-left: 16px;
}